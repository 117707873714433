import React from 'react';
import { ToastContainer, toast, Zoom } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './Toastify.css';

/* https://fkhadra.github.io/react-toastify/api/toast-container */

const setupToast = (name) => (msg) => toast[name](msg, {});

export const success = setupToast('success');
export const info = setupToast('info');
export const warn = setupToast('warn');
export const error = setupToast('error');

export const dismiss = () => toast.dismiss();

const Toasts = () => (
  <ToastContainer
    position="bottom-right"
    newestOnTop={true}
    hideProgressBar={false}
    transition={Zoom}
  />
);

export default Toasts;
