import React from 'react';
import cx from 'classnames';
import moment from 'moment';

import { footer } from './styles.module.css';

const Footer = ({ children }) => {
  return (
    <footer className={cx('body-2', footer)}>
      &copy; {moment().format('YYYY')} Clientbook Inc. All rights reserved.
    </footer>
  );
};

Footer.propTypes = {};

export default Footer;
