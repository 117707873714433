import React from 'react';
import { arrayOf, node, oneOfType } from 'prop-types';

import {} from './styles.module.css';

// Printing out an empty header, to still vertically center main content
const Header = ({ children }) => <header>{children}</header>;

Header.propTypes = {
  children: oneOfType([node, arrayOf(node)]),
};

export default Header;
