import React from 'react';
import { bool, node, string } from 'prop-types';
import cx from 'classnames';

import Header from 'site/Header';
import Footer from 'site/Footer';
import Loading from 'components/Loading';
import Toasts from 'components/Toast';

import 'styles'; // global styles
import { page, alignCenter } from './styles.module.css';
import { Helmet } from 'react-helmet';

const Page = ({ title, isLoading, center, children }) => {
  // console.log('%%% Page', isLoading);
  return (
    <div className={page}>
      <Helmet title={title} />
      <Header />
      <Toasts />

      {isLoading ? (
        <Loading size="page" />
      ) : (
        <main
          className={cx({
            [alignCenter]: !!center,
          })}
        >
          {children}
        </main>
      )}

      <Footer />
    </div>
  );
};

Page.propTypes = {
  title: string,
  isLoading: bool,
  center: bool,
  children: node.isRequired,
};

Page.defaultProps = {
  title: 'Clientbook Payments',
};

export default Page;
