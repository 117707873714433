import React from 'react';
import cx from 'classnames';
import { oneOf } from 'prop-types';

import { loader, section, page, button } from './styles.module.css';

export const SIZE_DEFAULT = 'section';
export const SIZE_OPTIONS = [SIZE_DEFAULT, 'page', 'button'];

const Loading = ({ size }) => {
  return (
    <div
      className={cx({
        [section]: size === 'section',
        [page]: size === 'page',
        [button]: size === 'button',
      })}
    >
      <div className={loader}>Loading...</div>
    </div>
  );
};

Loading.propTypes = {
  size: oneOf(SIZE_OPTIONS),
};

Loading.defaultProps = {
  size: SIZE_DEFAULT,
};

export default Loading;
